<template>
  <div class="a-page">
    <van-nav-bar
      title="购买协议"
      left-arrow
      @click-left="$router.go(-1)"
      fixed
    />
    <div>
      <h4
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: center;
          widows: 0;
        "
      >
        全游云电脑服务购买协议
      </h4>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri">&#xa0;</span>
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: 宋体">全游云电脑</span
        ><span style="font-family: 宋体">服务</span
        ><span style="font-family: 宋体">购买</span
        ><span style="font-family: 宋体">协议（以下简称“本协议”）是由杭州</span
        ><span style="font-family: 宋体">子默网络科技</span
        ><span style="font-family: 宋体">有限公司（以下简称“</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体">”）与用户（以下称“您”）就</span
        ><span style="font-family: 宋体">您</span
        ><span style="font-family: 宋体">在使用</span
        ><span style="font-family: 宋体">全游云电脑</span
        ><span style="font-family: 宋体"
          >期间使用我司所提供的服务等事宜所签订的具有法律效力的</span
        ><span style="font-family: 宋体">有效文书</span
        ><span style="font-family: 宋体">。</span>
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: 宋体">【特别提示】</span
        ><span style="font-family: Calibri; font-weight: bold">&#xa0;</span
        ><span
          style="
            font-family: 宋体;

            font-weight: bold;
            text-decoration: none;
          "
          >购买全游云电脑服务的</span
        ><span style="font-family: 宋体; font-weight: bold"
          >用户须确认自己具有完全民事行为能力，已年满</span
        ><span style="font-family: Calibri; font-weight: bold">18</span
        ><span style="font-family: 宋体; font-weight: bold">周岁，或已年满</span
        ><span style="font-family: Calibri; font-weight: bold">16</span
        ><span style="font-family: 宋体; font-weight: bold">周岁且</span
        ><span style="font-family: 宋体; font-weight: bold"
          >由合法渠道获得的可用于自由支配的货币资金</span
        ><span style="font-family: 宋体; font-weight: bold"
          >。未成年用户在使用</span
        ><span style="font-family: 宋体; font-weight: bold">全游云电脑</span
        ><span style="font-family: 宋体; font-weight: bold">服务或</span
        ><span style="font-family: 宋体; font-weight: bold">购买</span
        ><span style="font-family: 宋体; font-weight: bold"
          >时长前，必须得到家长或其他合法监护人的同意。</span
        ><span style="font-family: 宋体; font-weight: bold"
          >全游云电脑服务方</span
        ><span style="font-family: 宋体; font-weight: bold"
          >对于未成年人用户使用本服务过程中的行为，以及因其使用本服务而产生的一切后果不承担任何责任。</span
        >
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: 宋体; font-weight: bold">时长</span
        ><span style="font-family: 宋体; font-weight: bold">：</span
        ><span style="font-family: 宋体">是</span
        ><span style="font-family: 宋体">指全游云电脑服务方</span
        ><span style="font-family: 宋体">为</span
        ><span style="font-family: 宋体">您</span
        ><span style="font-family: 宋体">提供的用于</span
        ><span style="font-family: 宋体">全游云电脑</span
        ><span style="font-family: 宋体">上网服务</span
        ><span style="font-family: 宋体">的</span
        ><span style="font-family: 宋体">时间</span
        ><span style="font-family: 宋体">衡量</span
        ><span style="font-family: 宋体">标准。</span>
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: 宋体; font-weight: bold">第一条</span
        ><span style="font-family: 宋体; font-weight: bold"> </span
        ><span style="font-family: 宋体; font-weight: bold">服务说明</span>
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri">1.1</span
        ><span style="font-family: 宋体">时长属于在线提供的用于虚拟服务的</span
        ><span style="font-family: 宋体">充值</span
        ><span style="font-family: 宋体"
          >类商品，因其特殊属性，故不适用七日无理由退货等条款的约束，用户一旦</span
        ><span style="font-family: 宋体">购买</span
        ><span style="font-family: 宋体">成功，</span
        ><span style="font-family: 宋体">购买</span
        ><span style="font-family: 宋体">即确定完成，</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体">不提供任何退还或逆向兑换服务。</span>
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri">1.2</span
        ><span style="font-family: 宋体">购买方式</span
        ><span style="font-family: 宋体">提供</span
        ><span style="font-family: 宋体">包括但不限于</span
        ><span style="font-family: 宋体">微信等</span
        ><span style="font-family: 宋体">支付</span
        ><span style="font-family: 宋体">方式。</span>
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri">1.3</span
        ><span style="font-family: 宋体">用户使用上述</span
        ><span style="font-family: 宋体">支付</span
        ><span style="font-family: 宋体">方式进行</span
        ><span style="font-family: 宋体">购买</span
        ><span style="font-family: 宋体">后，应保留</span
        ><span style="font-family: 宋体">支付</span
        ><span style="font-family: 宋体"
          >订单号或账号以作为今后发生问题核对依据凭证。用户如就</span
        ><span style="font-family: 宋体">购买</span
        ><span style="font-family: 宋体"
          >服务中产生的问题进行投诉，但未提供任何真实、有效凭证的，</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体">将不做出任何补偿和赔偿</span
        ><span style="font-family: 宋体">。</span>
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: 宋体; font-weight: bold">第二条</span
        ><span style="font-family: 宋体; font-weight: bold"> </span
        ><span style="font-family: 宋体; font-weight: bold">使用规则</span>
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri">2.1</span
        ><span style="font-family: 宋体"
          >使用云电脑服务前首先您应关注我司的</span
        ><span style="font-family: 宋体">公众号（</span
        ><span style="font-family: 宋体">全游云电脑</span
        ><span style="font-family: 宋体">）</span
        ><span style="font-family: 宋体"
          >完成全游云电脑服务的注册，您可以通过您的手机号及验证码方式</span
        ><span style="font-family: 宋体">进行</span
        ><span style="font-family: 宋体"
          >注册，注册成功并进行实名认证后方可进行购买。购买时应使用您</span
        ><span style="font-family: 宋体">合法、有效账户</span
        ><span style="font-family: 宋体">进行购买</span
        ><span style="font-family: 宋体">，否则因此造成损失的，应由</span
        ><span style="font-family: 宋体">您独自承担</span
        ><span style="font-family: 宋体">。</span>
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri">2.2</span
        ><span style="font-family: 宋体">您应</span
        ><span style="font-family: 宋体">采用</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体">所指定的</span
        ><span style="font-family: 宋体">购买</span
        ><span style="font-family: 宋体">方式增加账户</span
        ><span style="font-family: 宋体">剩余</span
        ><span style="font-family: 宋体">的时长。</span>
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri">2.3</span
        ><span style="font-family: Calibri">&#xa0;</span
        ><span style="font-family: 宋体">购买</span
        ><span style="font-family: 宋体"
          >时长前，用户请务必仔细确认自己的账号及</span
        ><span style="font-family: 宋体">支付</span
        ><span style="font-family: 宋体">金额。</span>
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri">2.4</span
        ><span style="font-family: 宋体">如因</span
        ><span style="font-family: 宋体">您</span
        ><span style="font-family: 宋体">违反</span
        ><span style="font-family: 宋体">全游云电脑服务相关协议的</span
        ><span style="font-family: 宋体">，</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体"
          >可冻结其账号或终止其账号使用资格，且用户不得要求</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体">作任何补偿或赔偿。</span>
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri">2.5</span
        ><span style="font-family: Calibri">&#xa0;</span
        ><span style="font-family: 宋体">如果</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体"
          >发现因系统故障或其他任何原因导致的处理错误，</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体"
          >有权在以电子邮件、电话、或其他合理方式通知您后，纠正该错误。如果该措施导致您实际收到的时长少于您应获得的时长，经您申请且出示相关证据后，则</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体"
          >在确认该处理错误后会尽快将差额补足至您的账户。如果该错误导致您实际收到的时长大于您应获得的时长，则无论造成该错误的性质和原因如何，</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体">均有权从您的账户中直接扣除差额。</span>
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri">2.6</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体"
          >有权基于交易安全等方面的考虑不时设定涉及交易的相关事项，包括但不限于交易限额，交易次数等。</span
        >
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri">2.7</span
        ><span style="font-family: 宋体"
          >您理解并同意，因购买增加的全游云电脑服务时长，应在有效期间内使用完毕，若您未在有效期间内使用完毕的，视为您放弃增加的全游云电脑服务时长的权利。</span
        >
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri">2.8</span
        ><span style="font-family: 宋体"
          >您理解并同意，在全游云电脑服务有效时长临届满前使用的，根据有效期规则要求，您应在有效期届满后一次性使用完毕；如果中途有</span
        ><span style="font-family: 宋体">终止</span
        ><span style="font-family: 宋体">使用</span
        ><span style="font-family: 宋体">并且已过有效期的</span
        ><span style="font-family: 宋体">，则视为您自动放弃其</span
        ><span style="font-family: 宋体">剩余</span
        ><span style="font-family: 宋体"
          >时长。例如：购买的云电脑服务有效时长为</span
        ><span style="font-family: Calibri">5</span
        ><span style="font-family: 宋体">个小时，</span
        ><span style="font-family: 宋体">截止于</span
        ><span style="font-family: Calibri">2022</span
        ><span style="font-family: 宋体">年</span
        ><span style="font-family: Calibri">7</span
        ><span style="font-family: 宋体">月</span
        ><span style="font-family: Calibri">11</span
        ><span style="font-family: 宋体">日</span
        ><span style="font-family: Calibri">00:00:00</span
        ><span style="font-family: 宋体">止；若您在</span
        ><span style="font-family: Calibri">2022</span
        ><span style="font-family: 宋体">年</span
        ><span style="font-family: Calibri">7</span
        ><span style="font-family: 宋体">月</span
        ><span style="font-family: Calibri">10</span
        ><span style="font-family: 宋体">日</span
        ><span style="font-family: Calibri">23:30</span
        ><span style="font-family: 宋体"
          >进行使用，中途没有终止使用的，则可以允许使用时长截止至次日的</span
        ><span style="font-family: Calibri">4:30</span
        ><span style="font-family: 宋体">，即虽然在</span
        ><span style="font-family: Calibri">2022</span
        ><span style="font-family: 宋体">年</span
        ><span style="font-family: Calibri">7</span
        ><span style="font-family: 宋体">月</span
        ><span style="font-family: Calibri">11</span
        ><span style="font-family: 宋体">日</span
        ><span style="font-family: Calibri">00:00:00</span
        ><span style="font-family: 宋体">前仅剩余</span
        ><span style="font-family: Calibri">4</span
        ><span style="font-family: 宋体">小时</span
        ><span style="font-family: Calibri">30</span
        ><span style="font-family: 宋体"
          >分钟将到期，但是全游云电脑服务方还是让您使用截止至剩余时长使用完为止，但若中途终止使用且有剩余时长的，则视为您放弃剩余时长且该时长自动失效并清零。</span
        >
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri">2.9</span
        ><span style="font-family: 宋体">您</span
        ><span style="font-family: 宋体">不得通过或利用</span
        ><span style="font-family: 宋体">全游云电脑</span
        ><span style="font-family: 宋体">进行任何违法犯罪活动，否则</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体">有权终止服务且</span
        ><span style="font-family: 宋体">有权</span
        ><span style="font-family: 宋体">将依法向公安机关举报；</span
        ><span style="font-family: 宋体">您</span
        ><span style="font-family: 宋体">不得通过或利用</span
        ><span style="font-family: 宋体">全游云电脑服务</span
        ><span style="font-family: 宋体"
          >进行挖矿、恶意挂机、无操作长时间占用机器、游戏开挂等行为，否则</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体"
          >有权终止服务且采取包括但不限于封禁账号、不退还已</span
        ><span style="font-family: 宋体">购买</span
        ><span style="font-family: 宋体"
          >未使用部分的余额等措施；若因此造成</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体"
          >损失的，将由您承担因此造成的一切损失。</span
        >
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: 宋体; font-weight: bold">第三条</span
        ><span style="font-family: 宋体; font-weight: bold"> </span
        ><span style="font-family: 宋体; font-weight: bold">风险提示</span>
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri">3.1</span
        ><span style="font-family: 宋体"
          >全游云电脑服务方有权根据业务需求，随时变更服务或更新本协议的条款，并在相关的页面上及时提示修改内容。修改后的</span
        ><span style="font-family: 宋体">购买</span
        ><span style="font-family: 宋体"
          >协议一经公布即替代原来的协议。您可随时通过时长</span
        ><span style="font-family: 宋体">购买</span
        ><span style="font-family: 宋体"
          >页面查阅最新的全游云电脑服务购买协议。</span
        >
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri">3.2</span
        ><span style="font-family: Calibri">&#xa0;</span
        ><span style="font-family: 宋体">您</span
        ><span style="font-family: 宋体">在</span
        ><span style="font-family: 宋体">购买</span
        ><span style="font-family: 宋体"
          >前务必仔细确认自己的账号并仔细选择相关操作选项。如因</span
        ><span style="font-family: 宋体">您</span
        ><span style="font-family: 宋体">自身个人原因，</span
        ><span style="font-family: 宋体">如</span
        ><span style="font-family: 宋体">输入账号错误、操作不当或不了解</span
        ><span style="font-family: 宋体">购买</span
        ><span style="font-family: 宋体">计费方式等因素而损害自身权益的，</span
        ><span style="font-family: 宋体">您</span
        ><span style="font-family: 宋体">应自行承担责任，</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体">将不会作任何补偿或赔偿。</span>
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri">3.3</span
        ><span style="font-family: 宋体">如</span
        ><span style="font-family: 宋体">您</span
        ><span style="font-family: 宋体">以任何非法的方式或使用非</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体">所指定的</span
        ><span style="font-family: 宋体">购买</span
        ><span style="font-family: 宋体">方式进行</span
        ><span style="font-family: 宋体">购买</span
        ><span style="font-family: 宋体">的，</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体">不保证该</span
        ><span style="font-family: 宋体">购买</span
        ><span style="font-family: 宋体">顺利或者正确完成。如因此造成</span
        ><span style="font-family: 宋体">您</span
        ><span style="font-family: 宋体">权益受损时，</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体">不会作任何补偿或赔偿。</span
        ><span style="font-family: Calibri">&#xa0;</span>
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri">3.4</span
        ><span style="font-family: 宋体">如发生下列任何一种情况，</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体"
          >有权随时中断或终止向您提供本协议项下的网络服务而无需事先通知您：（</span
        ><span style="font-family: Calibri">1</span
        ><span style="font-family: 宋体">）您提供的个人资料不真实；（</span
        ><span style="font-family: Calibri">2</span
        ><span style="font-family: 宋体">）您违反本协议规定的购买规则；（</span
        ><span style="font-family: Calibri">3</span
        ><span style="font-family: 宋体">）您违反</span
        ><span style="font-family: 宋体">本协议其他约定</span
        ><span style="font-family: 宋体">。除前款所述情形外，</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体">同时保留在不事先通知</span
        ><span style="font-family: 宋体">您</span
        ><span style="font-family: 宋体"
          >的情况下随时中断或终止部分或全部网络</span
        ><span style="font-family: 宋体">购买</span
        ><span style="font-family: 宋体">服务的权利，对于</span
        ><span style="font-family: 宋体">购买</span
        ><span style="font-family: 宋体"
          >服务的中断或终止而造成的任何损失，</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体">无需对</span
        ><span style="font-family: 宋体">您</span
        ><span style="font-family: 宋体">或任何第三方承担任何责任。</span>
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri">3.5</span
        ><span style="font-family: 宋体">如用户违规使用非本人手机代</span
        ><span style="font-family: 宋体">购买</span
        ><span style="font-family: 宋体">，或者通过其他未经</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体">提供或认可的渠道</span
        ><span style="font-family: 宋体">违规</span
        ><span style="font-family: 宋体">购买时长的，</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体"
          >有权冻结该账户，并进行相应处罚，严重违规者将封号处理。</span
        >
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: 宋体; font-weight: bold">第四条</span
        ><span style="font-family: 宋体; font-weight: bold"> </span
        ><span style="font-family: 宋体; font-weight: bold"
          >免责声明和损害赔偿</span
        >
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri">4.1</span
        ><span style="font-family: Calibri"> </span
        ><span style="font-family: 宋体">因您自身的原因导致</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体"
          >无法提供时长购买服务或提供时长购买服务时发生任何错误而产生的任何损失或责任，由您自行负责，</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体">不承担责任，包括但不限于：</span>
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: 宋体">（</span
        ><span style="font-family: Calibri">1</span
        ><span style="font-family: 宋体">）您未按照本协议或</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体">不时公布的任何规则进行操作；</span>
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: 宋体">（</span
        ><span style="font-family: Calibri">2</span
        ><span style="font-family: 宋体">）因您的</span
        ><span style="font-family: 宋体">全游云电脑</span
        ><span style="font-family: 宋体">账号失效、丢失、被封停；</span>
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: 宋体">（</span
        ><span style="font-family: Calibri">3</span
        ><span style="font-family: 宋体"
          >）因您绑定的第三方支付机构账户的原因导致的损失或责任，包括您使用未经认证的第三方支付账户或使用非您本人的第三方支付账户，您的第三方支付账户被冻结、查封等；</span
        >
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: 宋体">（</span
        ><span style="font-family: Calibri">4</span
        ><span style="font-family: 宋体"
          >）您因账号密码泄露导致的财产损失；</span
        >
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: 宋体">（</span
        ><span style="font-family: Calibri">5</span
        ><span style="font-family: 宋体"
          >）因您个人的故意或重大过失所造成的财产损失。</span
        >
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri">4</span
        ><span style="font-family: Calibri">.2</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体"
          >系统因下列状况无法正常运作，使您无法使用各项服务时，我司不承担损害赔偿责任，该状况包括但不限于：</span
        >
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: 宋体">（</span
        ><span style="font-family: Calibri">1</span
        ><span style="font-family: 宋体">）在</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体"
          >公告之系统停机维护、升级、调整期间；</span
        >
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: 宋体">（</span
        ><span style="font-family: Calibri">2</span
        ><span style="font-family: 宋体"
          >）电信通讯或设备出现故障不能进行数据传输的；</span
        >
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: 宋体">（</span
        ><span style="font-family: Calibri">3</span
        ><span style="font-family: 宋体"
          >）因台风、地震、海啸、洪水、停电、战争、恐怖袭击、政府管制等不可抗力之因素，造成</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体">系统障碍不能执行业务的；</span>
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: 宋体">（</span
        ><span style="font-family: Calibri">4</span
        ><span style="font-family: 宋体"
          >）由于黑客攻击、电信部门技术调整或故障、网站升级、相关第三方的问题等原因而造成的服务中断或者延迟。</span
        >
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri">4</span
        ><span style="font-family: Calibri">.3</span
        ><span style="font-family: 宋体"
          >任何情况下，对于您购买时长时涉及由第三方提供相关服务的责任由该第三方承担，</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体"
          >不承担相应责任，如微信提供的支付服务等。</span
        >
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri">4</span
        ><span style="font-family: Calibri">.4</span
        ><span style="font-family: 宋体">用户同意保障和维护</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体"
          >及其他用户的利益，如因用户违反有关法律、法规或本协议项下的任何条款而给</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体"
          >或任何其他第三人造成损失，用户同意承担由此造成的损害赔偿责任。</span
        ><span style="font-family: Calibri">&#xa0;</span>
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: 宋体; font-weight: bold">第五条</span
        ><span style="font-family: 宋体; font-weight: bold"> </span
        ><span style="font-family: 宋体; font-weight: bold"
          >法律适用、管辖</span
        >
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri">5</span
        ><span style="font-family: Calibri">.1 </span
        ><span style="font-family: 宋体"
          >本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律。</span
        >
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri">5</span
        ><span style="font-family: Calibri">.2</span
        ><span style="font-family: Calibri">&#xa0;</span
        ><span style="font-family: 宋体">如您和</span
        ><span style="font-family: 宋体">全游云电脑服务方</span
        ><span style="font-family: 宋体"
          >之间因本协议发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交至杭州市</span
        ><span style="font-family: 宋体">拱墅区</span
        ><span style="font-family: 宋体">人民法院管辖。</span>
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: 宋体; font-weight: bold">第六条</span
        ><span style="font-family: 宋体; font-weight: bold"> </span
        ><span style="font-family: 宋体; font-weight: bold">其他</span>
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri">6</span
        ><span style="font-family: Calibri">.1</span
        ><span style="font-family: 宋体"
          >本协议任意一条被视为废止、无效或不可执行，该条款视为可分的且不影响本协议其余条款的有效性及执行性。</span
        >
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri">6</span
        ><span style="font-family: Calibri">.2</span
        ><span style="font-family: 宋体"
          >本协议项下所有的通知均可通过重要页面公告、电子邮件或常规的信件传送等方式进行；该等通知于发送之日视为已送达收件人。</span
        >
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri">6.3</span
        ><span style="font-family: 宋体"
          >本协议自您阅读完毕并完成注册后生效。</span
        >
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri">&#xa0;</span>
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: 宋体"> 杭州子默网络科技有限公司</span>
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri">&#xa0;</span>
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: 宋体"></span
        ><span style="font-family: Calibri">2022</span
        ><span style="font-family: 宋体">年</span
        ><span style="font-family: Calibri">07</span
        ><span style="font-family: 宋体">月</span
        ><span style="font-family: Calibri">10</span
        ><span style="font-family: 宋体">日</span>
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri">&#xa0;</span>
      </p>
      <p
        style="
          line-height: 150%;
          margin: 0pt;
          orphans: 0;
          text-align: justify;
          widows: 0;
        "
      >
        <span style="font-family: Calibri">&#xa0;</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.a-page {
  padding: 60px 16px 16px 16px;
  background: #f9f9f9;
  p {
    text-indent: 21px;
    * {
      font-size: 10.5px !important;
    }
  }
}
</style>
